var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-view', {
    attrs: {
      "id": "app-router"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }